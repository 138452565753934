import './src/theme/destyle.css'
import './src/theme/global.css'

import { ThemeProvider } from './src/theme'

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider {...props}>
      {element}
    </ThemeProvider>
  )
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  window.setTimeout(() => window.scrollTo(0, 0), 800)

  return false
}